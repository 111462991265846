<script>
	export default {
		props: ['location', 'buffetName'],
		data() {
			return {}
		},
	}
</script>
<template>
	<div class="map-link bold" @click.stop>
		<router-link :to="`/map/${location}/${buffetName}`">
			<span class="icon icon-pin"></span>
			<span class="location">{{location}}</span>
			<span class="gold">{{$t('map-link')}}</span>
		</router-link>
	</div>
</template>
<style scoped>
	.icon {
		color: var(--theme-color-3);
		margin-left: -5px;
	}
	.location {
		text-transform: uppercase;
		padding-right: 12px;
	}
</style>