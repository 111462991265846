<script>
	import SmartImage from "./smart-image";
	import NumberInput from "./number-input";

	export default {
		components: {SmartImage, NumberInput},
		props: ['value', 'disabled'],
		data() {
			return {
				open: false
			}
		},
		computed: {
			productInCart() {
				return this.$store.getters.getProductFromCart(this.value.id) || {};
			}
		},
		methods: {
			formatAllergens(allergens) {
				const translated = allergens.map(a => this.$t('allergen-' + a));
				return translated.join(', ');
			},
			updateQuantityInCart(quantity) {
				if(!this.disabled) {
					this.$store.dispatch('updateProductQuantityInCart', {product: this.value, quantity})
				}
			},
			getGrossPrice(product) {
				return product.price * (product.vat / 100 + 1);
			}
		}
	}
</script>
<template>
	<div class="shop-item">
		<div class="product" :class="{open: open}" @click="open = !open">
			<smart-image :img_key="value.image_key" size="large"/>
			<span class="icon-close"></span>
			<div class="texts">
				<div class="name bold">{{value.title}}</div>
				<p>{{value.lead}}</p>
				<p v-if="open && value.allergens">
					{{$t('allergens')}}: <b>{{formatAllergens(value.allergens)}}</b>
				</p>
				<h3>{{$n(getGrossPrice(value), 'currency')}}</h3>
			</div>
		</div>
		<div class="input-container">
			<number-input
					:value="productInCart.quantity"
					@input="updateQuantityInCart"
			></number-input>
		</div>
	</div>
</template>
<style scoped>
	.shop-item {
		--animation: 65ms ease-in all;
		margin-bottom: 30px;
	}
	.product {
		display: flex;
	}
	.product .smart-image {
		width: 33%;
		height: 21vw;
		max-height: 168px; /* 800 * 0.21 */
		min-width: 128px;
		margin: 0 22px auto 0;
		transition: var(--animation);
		border-radius: 10px;
	}
	.product .texts {
		width: calc(67% - 22px);
		transition: var(--animation);
	}
	.product .texts .name {
		margin-bottom: 5px;
	}
	.product.open {
		display: block;
		position: relative;
	}
	.product .icon-close {
		display: none;
	}
	.product.open .icon-close {
		display: block;
		position: absolute;
		top: 15px;
		right: 15px;
		background: #FFFFFF55;
		padding: 12px;
		border-radius: 50%;
		color: white;
	}
	.product.open .smart-image {
		margin-bottom: 14px;
		height: 50vw;
		max-height: 400px; /* 800 * 0.5 */
	}
	.product.open .smart-image,
	.product.open .texts {
		width: 100%;
	}
	.product.open .texts .name {
		margin-bottom: 10px;
	}
	.product .texts p:not(:last-of-type) {
		margin-bottom: 6px;
	}
	.product .texts p:not(:only-of-type):last-of-type {
		margin-bottom: 12px;
	}
	.product.open .texts h3 {
		margin-bottom: 16px;
	}

	.input-container {
		display: flex;
	}
	.input-container .number-input {
		width: calc(67% - 22px);
		min-width: 200px;
		margin-left: auto;
	}
</style>