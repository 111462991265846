<script>
	export default {
		props: {
			value: {
				type: Number,
				default: 0,
			},
			min: {
				type: Number,
				default: 0,
			},
			max: {
				type: Number,
				default: 99,
			}
		},
		computed: {
			isMin() {
				return this.value === this.min;
			},
			isMax() {
				return this.value === this.max;
			}
		},
		methods: {
			increase() {
				if(this.isMax) {
					return;
				}
				this.$emit('input', this.value + 1);
			},
			decrease() {
				if(this.isMin) {
					return;
				}
				this.$emit('input', this.value - 1);
			}
		}
	}
</script>
<template>
	<div class="number-input">
		<button
			class="bold"
			:disabled="isMin"
			@click="decrease">
			<span class="icon-minus"></span>
		</button>
		<div class="bold" :class="{highlighted: value > 0}">{{value}} db</div>
		<button
			class="bold"
			:disabled="isMax"
			@click="increase">
			<span class="icon-plus"></span>
		</button>
	</div>
</template>
<style scoped>
	.number-input {
		display: flex;
	}
	.number-input > * {
		flex: 1;
		text-align: center;
		padding: 12px 0 10px;
		border: none;
		background-color: white;
		box-shadow: 0 6pt 12pt #0000001A;
		border-radius: 4px;
		transition: 100ms all ease-in;
	}
	.number-input > div {
		margin: 0 5px;
	}
	.number-input > div.highlighted {
		background-color: var(--greey-bg-color);
	}
	.number-input button[disabled] {
		opacity: .5;
	}
</style>