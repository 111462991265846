<script>
	import ShopItem from "../../components/shop-item";
	import {getBuffetById, getBuffetStatusById} from "../../functions/api/content";
	import MapLink from "../../components/map-link";
	import {getBalance, getMe, saveCart, savePayment} from "../../functions/api/payment";
	import store from "@/store";
	export default {
		components: {MapLink, ShopItem},
		beforeRouteEnter(to, from, next) {
			if(!store.state.cart.buffetId) {
				return next({name: 'buffet-list'});
			}
			if(store.state.cart.items.length === 0) {
				return next({name: 'buffet-subpage'});
			}
			next();
		},
		data() {
			return {
				pageLoading: false,
				accountLoading: false,
				hasCard: false,
				currentBalanceOnClubCard: 0,
				buffet: {},
				balanceLoading: false,
				buffetTimeout: null
			}
		},
		computed: {
			userToken() {
				return this.$store.state.userToken;
			},
			cart() {
				return this.$store.state.cart;
			},
			totalPrice() {
				return this.$store.getters.totalPrice;
			},
			notEnoughCredit() {
				return this.currentBalanceOnClubCard < this.totalPrice;
			},
			buffetOverloaded() {
				return this.buffet.concurrentOrdersCounter >= this.buffet.maxConcurrentOrders;
			},
			clubCardBtnDisabled() {
				return this.notEnoughCredit || !this.buffet.open || this.cart.items.length === 0 || this.buffetOverloaded;
			},
			creditCardBtnDisabled() {
				return !this.hasCard || !this.buffet.open || this.cart.items.length === 0 || this.buffetOverloaded;
			}
		},
		created() {
			this.accountLoading = true;
			this.refreshAccount().then(acc => {
				this.accountLoading = false;
				if(!acc || !acc.connected_cardnumber) {
					return;
				}
				this.hasCard = true;
				this.refreshBalance();
			});
			this.refreshBuffet(this.cart.buffetId)
				.then(this.keepBuffetUptoDate)
		},
		destroyed() {
			clearTimeout(this.buffetTimeout);
		},
		methods: {
			saveCart(cart) {
				return saveCart(this.userToken, {...cart, locale: this.$store.getters['getSavedLocale']});
			},
			keepBuffetUptoDate() {
				this.refreshBuffetStatus(this.cart.buffetId);
				this.buffetTimeout = setTimeout(() => {
					this.keepBuffetUptoDate();
				},3000);
			},
			refreshBuffet(id) {
				return getBuffetById(id)
					.then(res => this.buffet = res)
			},
			refreshBuffetStatus(id) {
				return getBuffetStatusById(id)
					.then(res => this.buffet = {...this.buffet, ...res})
			},
			refreshAccount() {
				return getMe(this.userToken);
			},
			refreshBalance() {
				this.balanceLoading = true;
				return getBalance(this.userToken)
					.then(res => this.currentBalanceOnClubCard = res.amount)
					.catch(console.error)
					.then(() => this.balanceLoading = false);
			},
			payWithClubCard() {
				if(this.clubCardBtnDisabled) {
					return;
				}
				this.pay('C')
					.then(payment => {
						this.$router.push('/order-progress/' + payment.foodOrderId)
					}, payment => {
						console.error(payment);
						this.resetCart();
						if(payment.statusCode === 503) {
							this.$router.push('/buffet-closed');
							return;
						}
						this.$router.push('/order-failed');
					});
			},
			payWithCreditCard() {
				if(this.creditCardBtnDisabled) {
					return;
				}
				this.pay('B')
					.then(
						payment => location.href = payment.redirectUrl,
						payment => {
							console.error(payment);
							this.resetCart();
							if(payment.statusCode === 503) {
								this.$router.push('/buffet-closed');
								return;
							}
							this.$router.push('/order-failed');
						}
					);
			},
			pay(paymentType) {
				this.pageLoading = true;
				return this.saveCart(this.cart).then(cart => {
					return savePayment(this.userToken, cart.id || this.cart.id, paymentType)
						.then(payment => {
							this.resetCart();
							this.pageLoading = false;
							if(payment.status === 'payment-failed') {
								throw payment;
							}
							return payment;
						});
				});
			},
			resetCart() {
				/* while keeping the buffet selected */
				this.$store.dispatch('resetCart');
			}
		}
	}
</script>
<template>
	<div class="container" v-loading="pageLoading">
		<section class="header">
			<h1>{{$t('title-cart')}}</h1>
			<h2>
				{{buffet.title}}
				<span v-if="buffet.id && !buffet.open" class="danger"> - {{$t('buffet-closed')}}</span>
			</h2>
			<div :class="{'grayscale': !buffet.open}">
				<map-link :location="buffet.location" :buffet-name="buffet.title"></map-link>
			</div>
		</section>
		<section class="summ">
			<h2>{{$t('summ')}}</h2>
			<h2>{{$n(totalPrice, 'currency')}}</h2>
		</section>

		<section v-if="buffet.id && !buffet.open" class="closed-buffet">
			<div class="row">
				<h3 class="danger">{{$t('buffet-closed-desc')}}</h3>
			</div>
			<div>
				<router-link to="/buffet-list" class="gold-button btn">
					{{$t('back-to-home')}}
				</router-link>
			</div>
		</section>

		<section v-else class="pay">
			<div v-if="userToken">
				<div>
					<p v-if="!hasCard && !accountLoading" class="error text-center">
						{{$t('card-connected-false')}}
					</p>
					<p v-else-if="notEnoughCredit && !balanceLoading" class="error">
						{{$t('notEnoughBalanceOnClubCard')}}
					</p>
					<p v-if="buffetOverloaded" class="error">
						{{$t('buffet-overloaded')}}
					</p>
					<button
							class="gold-button large"
							:class="{disabled: clubCardBtnDisabled}"
							@click="payWithClubCard"
					>
						{{$t('pay-with-fan-card')}}
					</button>
				</div>
				<button
					class="gold-button large"
					:class="{'disabled': creditCardBtnDisabled}"
					@click="payWithCreditCard"
				>
					<span class="icon icon-card"></span>
					{{$t('pay-with-credit-card')}}
				</button>
			</div>
			<div v-else class="login-box">
				<div class="icon-circle">
					<span class="icon icon-profile"></span>
				</div>
				<h3>{{$t('login-message')}}</h3>
				<button class="gold-button large">
					{{$t('login-button')}}
				</button>
			</div>
		</section>

		<section v-if="cart.items.length > 0" class="products" :class="{'grayscale': !buffet.open}">
			<h2>{{$t('title-cart-list')}}</h2>
			<shop-item
					v-for="item in cart.items"
					:key="item.id"
					:value="item"
					:disabled="!buffet.open"
			></shop-item>
		</section>
		<section v-else class="back">
			<router-link to="/buffet-subpage">
				<button class="gold-button">
					{{$t('new-order')}}
				</button>
			</router-link>
		</section>
	</div>
</template>
<style scoped>
	.text-left {
		text-align: left;
	}
	.header {
		margin-bottom: 24px;
	}
	.header h2 {
		margin-bottom: 8px;
	}
	.error {
		border: 1px solid #ED9790;
		border-radius: 4px;
		background-color: #ED979066;
		padding: 16px;
		color: #454a49;
	}
	.products {
		padding-bottom: 10px;
	}
	.summ {
		display: flex;
		margin: 0 -20px 20px;
		padding: 20px;
		background-color: var(--greey-bg-color);
	}
	.summ h2 {
		margin: 0;
	}
	.summ h2:first-of-type {
		flex: 1;
	}
	.pay,
	.closed-buffet {
		text-align: center;
		padding-bottom: 30px;
		border-bottom: 1px solid #d4d4d4;
		margin-bottom: 24px;
	}
	.closed-buffet h3 {
		margin-bottom: 24px;
	}
	.login-box {
		background: white;
		border-radius: 4px;
		padding: 0 22px 10px;
		box-shadow: 0 8px 30px #e1e1e1;
		margin-top: 50px;
	}
	.login-box .icon-circle {
		position: relative;
		top: -30px;
		margin: auto;
		width: 60px;
		height: 60px;
		background-color: var(--theme-color-3);
		border-radius: 50%;
		display: flex;
		color: white;
		font-size: 38px;
	}
	.login-box .icon-circle .icon {
		margin: auto;
	}
	.login-box h3 {
		padding: 0 10%;
		margin-top: -10px;
		margin-bottom: 20px;
	}
	.back .gold-button {
		width: 100%;
	}
	.pay .gold-button {
		width: 100%;
		padding: 20px 29px 18px;
		margin-bottom: 20px;
	}
</style>
